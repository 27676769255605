@tailwind base;

.link {
  @apply underline;
  @apply text-blue-500;
}

.select__input {
  color: transparent;
}

@tailwind components;

@tailwind utilities;

@import './components.css';

.ui.search .prompt {
  border-radius: 0.28571429rem;
  width: 16em;
}
.ui.popup {
  animation-delay: 0.11s;
  animation-duration: 0.11s;
  animation-name: hover;
}

@keyframes hover {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
