.toggle-checkbox:checked {
  right: 0px;
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}

.toggle-checkbox:checked + .toggle-label {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}
